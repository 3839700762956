@import '_variables.scss';

html, body {
    height : auto !important;
}

body {
    padding          : 0;
    margin           : 0;
    font-family      : $ff-roboto;
    font-size        : $size-normal;
    color            : $black;
    max-width        : 1920px;
    margin-inline    : auto;
    box-shadow       : 0 0 9px $blue40;
    background-color : $light-grey;
}

.btn {
    background-color : $orange;
    color            : $white;
    border-radius    : 0;
    padding          : 10px 25px;
    margin-top       : 30px;
    outline          : 0;
    border           : 0;

    &:hover, &:target, &:focus, &:active {
        background-color : darken($orange, 5%) !important;
        color            : $white !important;
    }
}

.btn-link {
    position        : relative;
    text-decoration : none;
    color           : $orange;
    padding         : 0 0 0 30px;
    margin-top      : 0;
    outline         : 0;
    border          : 0;
    font-size       : $size-small;
    transition      : all .3s ease;

    &::after {
        content         : '';
        display         : block;
        position        : absolute;
        background      : transparent url("/public/images/fleche.svg") center no-repeat;
        width           : 12px;
        height          : 12px;
        background-size : contain;
        top             : 50%;
        left            : 0;
        transform       : translateY(-50%);
    }

    &:hover, &:target, &:focus, &:active {
        color : darken($orange, 5%) !important;
    }
}

.btn-link-project {
    @extend .btn-link;
    padding : 0 30px 0 0;
    color   : $light;
    height  : fit-content;

    &::after {
        right           : 10px;
        left            : unset;
        background      : transparent url("/public/images/fleche-white.svg") center no-repeat;
        background-size : contain;
    }

    &:hover, &:target, &:focus, &:active {
        color : darken($white, 5%) !important;
    }
}

.popup {
    width     : 100%;
    max-width : 500px;
    position  : fixed;
    left      : 50%;
    top       : 25px;
    transform : translateX(-50%);
    z-index   : 100;

    &-container {
        font-size : $size-small;
        padding   : 8px;
    }

    &-container span {
        margin-top   : 5px;
        margin-right : 20px;
        cursor       : pointer;
    }

    &.popup-danger {
        color            : $danger;
        background-color : $bg-danger;
    }

    &.popup-success {
        color            : $success;
        background-color : $bg-success;
    }
}

.container {
    max-width : 1500px !important;
}

.accroche-hero-page {
    position       : absolute;
    top            : 50%;
    left           : 50%;
    transform      : translate(-50%, -50%);
    color          : $white;
    text-align     : center;
    width          : 100%;
    max-width      : 950px;
    font-family    : $ff-inter;
    padding-inline : 10px;
}

.hero-page .accroche-hero-page > .title {
    font-size      : $size-3xl;
    font-weight    : 600;
    border-bottom  : .5px solid #7E8084;
    padding-bottom : 30px;
    padding-inline : 50px;
    margin-bottom  : 30px;
    margin-inline  : auto;
    width          : fit-content;
    @include media-breakpoint-down(lg) {
        font-size      : 50px;
        padding-inline : 25px;
    }
}

.hero-login {
    background      : linear-gradient(rgba(#3A5073, .7), rgba(#3A5073, .7)), url("/public/images/login.jpeg") no-repeat;
    background-size : cover;
    height          : 550px;
    position        : relative;
}

.hero-realisation {
    background      : linear-gradient(rgba(#3A5073, .7), rgba(#3A5073, .7)), url("/public/images/realisation.png") no-repeat;
    background-size : cover;
    height          : 550px;
    position        : relative;
}

.hero-contact {
    background      : linear-gradient(rgba(#3A5073, .7), rgba(#3A5073, .7)), url("/public/images/contact.png") no-repeat;
    background-size : cover;
    height          : 550px;
    position        : relative;

    .accroche-hero-page .hero-contact-content .phone a, .accroche-hero-page .hero-contact-content .email a {
        font-size       : $size-normal;
        text-decoration : none;
        color           : inherit;
    }

    .accroche-hero-page .hero-contact-content .title {
        font-weight : 600;
        font-size   : $size-large;
    }

    .accroche-hero-page .hero-contact-content > p {
        margin : 0;
    }
}

.hero-home {
    background      : linear-gradient(rgba(#3A5073, .7), rgba(#3A5073, .7)), url("/public/images/hero.jpg") no-repeat;
    background-size : cover;
    height          : 700px;
    position        : relative;


    .accroche-hero-page .name {
        font-size      : $size-largest;
        letter-spacing : 19.32px;
        text-transform : uppercase;
    }

    .accroche-hero-page .title {
        font-size    : $size-3xl;
        margin-block : 18px;
        font-weight  : 600;
    }

    .accroche-hero-page .subtitle {
        font-weight   : 600;
        margin-bottom : 50px;
    }

    .accroche-hero-page .description {
        font-size : $size-large;
    }

    @include media-breakpoint-down(lg) {
        .accroche-hero-page .name {
            font-size      : $size-large;
            letter-spacing : 15px;
        }

        .accroche-hero-page .title {
            font-size : 45px;
        }
    }
}

.social-image {
    width : 25px;
}

footer.footer {
    font-size   : $size-small;
    color       : $grey;
    padding-top : 100px;

    @include media-breakpoint-down(xl) {
        .footer-content {
            flex-direction : column;
        }

        .map {
            flex-direction : column;
            margin-top     : 30px;
        }
    }

    .footer-small {
        background-color : $blue;
        color            : $white;
        font-size        : $extra-small;
        text-align       : center;
        margin-top       : 70px;

        a {
            text-decoration : none;
            color           : $white;
        }

        a:hover {
            color : $orange;
        }
    }


    .resume {
        max-width : 540px;
    }

    .map ul {
        padding-left : 0;
    }

    .map {
        gap : 30px;
    }

    .map li {
        list-style-type : none;
    }

    .map li.title {
        font-size     : $size-large;
        color         : $black;
        margin-bottom : 30px;
    }

    .map li.item {
        color         : $grey;
        margin-bottom : 5px;
    }

    .map li .item-link {
        text-decoration : none;
        color           : $grey;
    }
}

.optimiser {
    padding-block    : 150px;
    background-color : $white;

    .bg-image {
        background      : linear-gradient(rgba(#3A5073, .7), rgba(#3A5073, .7)), url("/public/images/optimiser.jpg") no-repeat;
        background-size : cover;
        height          : 500px;
        position        : relative;
        transition      : height .3s ease;
        @include media-breakpoint-down(lg) {
            height : 350px;
        }
    }

    .optimiser-content {
        color     : $white;
        position  : absolute;
        left      : 40%;
        top       : 50%;
        transform : translate(-50%, -50%);

        > p {
            font-size   : $size-2xl;
            line-height : 70px;
            max-width   : 650px;
            font-weight : 600;
        }
    }

    @include media-breakpoint-down(lg) {
        padding-block : 0;

        .optimiser-content {
            Left      : 30px;
            right     : 30px;
            transform : translateY(-50%);
        }

        .optimiser-content > p {
            font-size   : 40px;
            line-height : 50px;
        }
    }
}

.testimonials {
    margin-top    : 70px;
    margin-bottom : 150px;
    text-align    : center;

    .card-wrapper {
        display         : flex;
        justify-content : center;
        column-gap      : 30px;
        row-gap         : 70px;
        flex-wrap       : wrap;
    }

    > .title {
        margin-bottom : 70px;
        font-size     : $size-2xl;
        @include media-breakpoint-down(xl) {
            font-size : 45px;
        }
    }

    .carousel-indicators {
        bottom : -50px;
    }

    .carousel-indicators [data-bs-target] {
        background-color : $orange;
    }

    .card {
        max-width      : 950px;
        display        : flex;
        flex-direction : column;
        align-items    : center;
        border         : 0;
        border-radius  : 0;
        padding        : 15px;
        margin-inline  : auto;
        //@include media-breakpoint-down(xxl) {
        //    width : calc((100% / 2) - 30px);
        //}
        //@include media-breakpoint-down(lg) {
        //    width : 100%;
        //}
    }

    .card-image {
        width         : 80px;
        height        : 80px;
        margin-bottom : 30px;
    }

    .card-title {
        font-size : $size-large;
    }

    .card-body {
        text-align    : center;
        font-size     : $size-small;
        color         : $grey;
        margin-bottom : 0;
    }

    .card-footer {
        background : none;
        text-align : center;
        font-size  : $size-normal;
        border     : none;

        > p {
            margin     : 0;
            font-style : italic;
        }

        > .name {
            color       : $orange;
            font-weight : 600;
        }

        > .company {
            color : $grey;
        }
    }
}

section.services {
    margin-top    : 125px;
    margin-bottom : 150px;

    .heading {
        text-align : center;
    }

    .heading .title {
        font-size : $size-2xl;
        @include media-breakpoint-down(lg) {
            font-size : 45px;
        }
    }

    .heading .subtitle {
        color : $grey;
    }

    .services-content {
        display         : flex;
        flex-direction  : row;
        justify-content : space-between;
        gap             : 15px;
        min-height      : 480px;
        height          : auto;
        margin-block    : 70px;
        flex-wrap       : wrap;
    }

    .card {
        width            : calc((100% / 4) - 30px);
        border           : 0;
        background-color : $white;
        position         : relative;
        border-radius    : 0;
        @include media-breakpoint-down(lg) {
            width : calc(50% - 30px);
        }
        @include media-breakpoint-down(md) {
            width : 100%;
        }
    }

    .card-content-image {
        height          : 330px;
        display         : flex;
        justify-content : center;
        align-items     : center;
        @include media-breakpoint-down(lg) {
            margin : 16px;
        }
    }

    .card-image {
        max-width : 186px;
    }

    .card-body .card-title {
        font-size   : $size-normal;
        font-weight : 600;
    }

    .card-body p {
        color     : $grey;
        font-size : $size-small;
    }
}

.call-to-action {
    display       : flex;
    gap           : 100px;
    margin-bottom : 150px;

    .right {
        position     : relative;
        margin-right : 8px;

        &::after {
            content          : "";
            position         : absolute;
            display          : block;
            width            : 40%;
            height           : 40%;
            background-color : $orange;
            top              : -8px;
            right            : -8px;
            z-index          : -1;
        }
    }

    @include media-breakpoint-down(xxl) {
        .image {
            max-width : 600px;
        }
    }
    @include media-breakpoint-down(xl) {
        gap : 30px;

        .image {
            max-width : 400px;
            width     : 100%;
        }
    }
    @include media-breakpoint-down(lg) {
        flex-direction : column-reverse;
        padding-inline : 16px;

        .image {
            object-fit : cover;
            max-width  : 100%;
            max-height : 200px;
        }

        .title {
            font-size   : 40px !important;
            line-height : normal !important;
        }
    }

    @include media-breakpoint-up(lg) {
        .image {
            min-width : 400px;
        }
    }

    .title {
        font-size   : $size-xl;
        font-weight : 600;
        line-height : 70px;
    }

    .paragraph {
        font-size    : $size-normal;
        color        : $grey;
        margin-block : 30px;
    }

    .btn {
        margin-top : 0;
    }
}

section.reassurance {
    padding-block    : 150px;
    background-color : $white;
    text-align       : center;

    .heading .title {
        font-size : $size-2xl;
        @include media-breakpoint-down(lg) {
            font-size : 45px;
        }
    }

    .reassurance-content {
        display         : flex;
        justify-content : space-between;
        margin-top      : 70px;
        gap             : 30px;
        flex-wrap       : wrap;
        padding-inline  : 16px;
    }

    .card {
        width            : calc((100% / 4) - 30px);
        border           : 0;
        background-color : inherit;
        @include media-breakpoint-down(lg) {
            width : calc(50% - 30px);
        }
        @include media-breakpoint-down(md) {
            width : 100%;
        }
    }

    .card-content-image {
        display         : flex;
        gap             : 20px;
        justify-content : center;
    }

    .card-content-image img {
        max-width : 120px;
    }

    .card-title {
        font-size   : $size-normal;
        font-weight : 600;
    }

    .card-body .description {
        color     : $grey;
        font-size : $size-small;
    }
}

.example-project {
    display        : flex;
    flex-direction : row-reverse;
    gap            : 100px;
    margin-block   : 150px;

    .right {
        position    : relative;
        margin-left : 8px;

        &::after {
            content          : "";
            position         : absolute;
            display          : block;
            width            : 40%;
            height           : 40%;
            background-color : $orange;
            bottom           : -8px;
            left             : -8px;
            z-index          : -1;
        }
    }

    @include media-breakpoint-down(xxl) {
        .image {
            max-width : 600px;
        }
    }
    @include media-breakpoint-down(xl) {
        gap : 30px;

        .image {
            max-width : 400px;
            width     : 100%;
        }
    }
    @include media-breakpoint-down(lg) {
        flex-direction : column-reverse;
        padding-inline : 16px;
        .image {
            object-fit : cover;
            max-width  : 100%;
            max-height : 200px;
        }

        .title {
            font-size   : 40px !important;
            line-height : normal !important;
        }
    }

    @include media-breakpoint-up(lg) {
        .image {
            min-width : 400px;
            max-width : 600px;
        }
    }

    .title {
        font-size   : $size-xl;
        font-weight : 600;
        line-height : 70px;
    }

    .paragraph {
        font-size    : $size-normal;
        color        : $grey;
        margin-block : 30px;
    }

    .btn {
        margin-top : 0;
    }
}

section.contact {
    max-width     : 900px;
    margin-top    : 150px;
    margin-bottom : 70px;
    margin-inline : auto;

    .form > div {
        margin-bottom : 8px;
        @include media-breakpoint-up(md) {
            margin-bottom : 30px;
        }
    }

    .left {
        display         : flex;
        flex-direction  : column;
        justify-content : space-between;
        gap             : 8px;
    }

    input, textarea {
        font-size     : $size-small;
        border-radius : 0;

        &:focus {
            box-shadow : none;
            outline    : 2px solid $orange;
            border     : none;
        }

        &::placeholder {
            font-style : italic;
            color      : #8B8B8B;
            font-size  : $extra-small;
        }
    }

    textarea {
        min-height : 150px;
        @include media-breakpoint-up(md) {
            min-height : 100%;
        }
    }

    label {
        font-size : $size-normal;
        color     : $grey;
    }

    .error {
        font-size     : 12px;
        color         : $danger;
        margin-top    : 8px;
        display       : inline-block;
        border-radius : 6px;

        &:empty {
            display : none;
        }

        ul {
            margin  : 0;
            padding : 3px 8px;
        }

        li {
            list-style-type : none;
        }
    }

    .content-information {
        color            : $grey;
        background-color : $white;
        padding          : 15px;
        font-size        : $size-normal;
        margin-bottom    : 50px;
    }

    .information-contact {
        font-size    : $extra-small;
        color        : $grey;
        font-style   : italic;
        padding-left : 5px;
    }
}

section.container-faq {
    margin-inline    : auto;
    max-width        : 900px;
    background-color : $blue;
    padding          : 16px;
    color            : $white;
    margin-bottom    : 150px;

    .title {
        margin-bottom : 30px;
    }

    .highlight {
        font-weight : bold;
        color       : $orange;
        font-size   : $size-normal;
    }

    .faq-content {
        margin-left : 50px;
    }

    .question {
        font-size : $size-normal;
        margin    : 0;
    }

    .response {
        font-size : $extra-small;
        color     : $light-grey;
    }
}

section.realisation {
    margin-block : 150px;

    .realisation-wrapper {
        display         : flex;
        flex-wrap       : wrap;
        gap             : 15px;
        justify-content : center;
    }

    .link-card {
        display : block;
    }

    .card-image img {
        width           : 100%;
        aspect-ratio    : 1/1;
        object-fit      : cover;
        object-position : left;
        transition      : transform .5s ease;
    }

    .card {
        position      : relative;
        aspect-ratio  : 1/1;
        max-width     : 100%;
        border        : 0.5px solid $light-grey;
        overflow      : hidden;
        border-radius : 0;

        &:hover .card-footer {
            transform : translateX(0);
        }

        @include media-breakpoint-up(sm) {
            max-width : calc(50% - 8px);
        }
        @include media-breakpoint-up(md) {
            max-width : calc((100% / 3) - 15px);
        }
        @include media-breakpoint-up(lg) {
            max-width : calc(25% - 15px);
            min-width : 210px;
        }
    }

    .card .card-body {
        padding : 0;
    }

    .card .card-footer {
        position        : absolute;
        background      : linear-gradient(rgba($blue, .8), rgba($blue, .8));
        transition      : transform .5s ease;
        border-radius   : 0;
        left            : 0;
        right           : 0;
        bottom          : 0;
        display         : block;
        text-decoration : none;
        padding         : 8px 15px;
        @include media-breakpoint-up(lg) {
            padding   : 0 0 15px 15px;
            top       : 0;
            transform : translateX(-100%);
        }
    }

    .card .footer-title {
        color     : $white;
        font-size : $size-large;
        @include media-breakpoint-up(lg) {
            position : absolute;
            bottom   : 15px;
            left     : 15px;
        }
    }
}

section.realisation-detail {
    margin-block : 75px 150px;
    max-width    : 1200px !important;

    .realisation-detail-wrapper {
        display         : flex;
        gap             : 30px;
        justify-content : center;
        flex-direction  : column;
        @include media-breakpoint-up(lg) {
            flex-direction : row;
            gap            : 50px;
        }
    }

    .project-detail-image {
        position    : relative;
        height      : 100%;
        margin-left : 8px;

        &::after {
            content          : "";
            position         : absolute;
            display          : block;
            width            : 40%;
            height           : 40%;
            background-color : $orange;
            bottom           : -8px;
            left             : -8px;
            z-index          : -1;
        }
    }

    .project-detail-image img {
        width           : 100%;
        height          : 300px;
        object-fit      : cover;
        object-position : top left;
        transition      : transform .5s ease;
        border          : 0.5px solid $light-grey;
        @include media-breakpoint-up(lg) {
            aspect-ratio : 9/12;
            width        : 400px;
            height       : auto;
        }
    }

    .project-detail-description {
        color       : $grey;
        width       : 100%;
        padding-top : 15px;
        @include media-breakpoint-up(lg) {
            //width : 50%;
        }

        a {
            color           : $orange;
            text-decoration : none;
        }
    }

    .project-detail-description h1 {
        font-size : $size-largest;
        color     : $black;
    }
}

section.realisation-random {
    margin-block : 150px;
    max-width    : 1200px !important;

    .title {
        margin-bottom : 70px;
        font-size     : $size-2xl;
        text-align    : center;
        @include media-breakpoint-down(xl) {
            font-size : 45px;
        }
    }

    .realisation-random-wrapper {
        display : flex;
        gap     : 30px;
        @include media-breakpoint-down(sm) {
            flex-direction : column;
        }

        .card {
            width    : 100%;
            position : relative;
            border   : 0.5px solid $light-grey;
        }

        .card-body {
            padding : 0;
        }

        .card-image img {
            height     : 500px;
            width      : 100%;
            object-fit : cover;
        }

        .card .card-footer {
            position        : absolute;
            background      : linear-gradient(rgba($blue, .8), rgba($blue, .8));
            transition      : transform .5s ease;
            border-radius   : 0;
            left            : 0;
            right           : 0;
            bottom          : 0;
            display         : flex;
            justify-content : space-between;
            text-decoration : none;
            padding         : 8px 15px;
        }

        .card .footer-title {
            color     : $white;
            font-size : $size-large;
        }
    }
}

section.connection {
    margin-block : 150px;
    max-width    : 700px !important;

    button.btn {
        width        : auto;
        margin-left  : auto;
        margin-right : auto;
        @extend .btn;
    }

    input {
        margin-top    : 15px;
        font-size     : $size-small;
        border-radius : 0;

        &:focus {
            box-shadow : none;
            outline    : 2px solid $orange;
            border     : none;
        }

        &::placeholder {
            font-style : italic;
            color      : #8B8B8B;
            font-size  : $extra-small;
        }
    }

    label {
        font-size : $size-normal;
        color     : $grey;
    }

    .form > div {
        margin-bottom : 30px;
    }

    .form-group {
        margin-top : 30px;
    }
}

.collaborate {
    font-size  : $extra-small;
    color      : $grey;
    margin-top : 30px;
    font-style : italic;
}

#toggle-nav {
    img {
        height     : 30px;
        width      : 30px;
        object-fit : contain;
    }

    @include media-breakpoint-up(lg) {
        display : none;
    }

    > span {
        display : none;
    }
}

section.mentions_legales {
    margin-block : 150px;

    span.highlight {
        color : $orange;
    }

    .orange {
        color : $orange;
    }
}

.sidebar-wrapper > .sidebar a {
    text-decoration : none;
    font-size       : $extra-small;
}

.main-content {
    font-size : $extra-small;
}

.wrapper .main-content {
    background-color : $white;
}

.content-body table.table thead {
    a {
        text-decoration : none;
    }
}

.content-body table.table tbody tr {
    border-bottom : 1px solid $blue40;

    td {
        color : $grey;
    }

    a {
        text-decoration : none;
    }
}

.form-check .form-check-input {
    margin-left : unset !important;
}

.pagination .page-item a {
    font-size : $extra-small;
    border    : none;
}

.page-actions .btn {
    font-size : $extra-small;
    padding   : 8px 10px;
}