nav.navbar {
    z-index : 10;
    @include media-breakpoint-down(lg) {
        background-color : $blue;
    }
}

nav.navbar, nav.navbar .navbar-brand > img {
    transition : all .75s ease;
}

nav.navbar .nav-link {
    color       : $white;
    font-size   : $size-normal;
    font-weight : 400;
    opacity     : .8;
    transition  : color .3s ease;

    &:hover {
        opacity : 1;
    }
}

nav.navbar .nav-item {
    margin-inline : 25px;
}

.navbar .navbar-toggler, .navbar .btn-close {
    padding        : 5px;
    width          : 45px;
    display        : flex;
    flex-direction : column;
    gap            : 10px;
    margin-right   : 20px;
    border         : 0;

    &:focus {
        box-shadow : none;
        outline    : 0;
    }

    .navbar-toggler-icon-trait {
        width      : 100%;
        background : $white;
        height     : 2px;
        display    : inline-block;

        &:last-child {
            width : 40%;
        }
    }
}