@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;600;700&display=swap');

$orange       : #FF4B0A;
$blue         : #3A5073;
$blue40       : #B0B9C7;
$blue70       : #75859D;
$light-grey   : #F2F7FF;
$white        : #FFFFFF;
$black        : #000000;
$light        : #DDDDDD;
$grey         : #5E5E5E;

$danger       : #903030;
$bg-danger    : #F2CDCD;
$success      : #3C6D47;
$bg-success   : #8FC571;

$ff-roboto    : 'Roboto', sans-serif;
$ff-inter     : 'Inter', sans-serif;

$extra-small  : 14px;
$size-small   : 16px;
$size-normal  : 18px;
$size-large   : 20px;
$size-largest : 24px;
$size-xl      : 55px;
$size-2xl     : 64px;
$size-3xl     : 70px;
